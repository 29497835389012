@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
--sidebar-flex:1.5;
--condiv-flex:6;
--fontsize-p:1.2rem;
--fontsize-nav:1.6rem;
--typing-font:3rem;
}
*
{
  margin:0;
  padding: 0;
}
body
{
  font-family: 'Raleway';
}
.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
/* .condiv class is for the right side content container*/
.condiv
{
  padding: 100px ;
  background-color: #f4f5f9;
  flex:6 1;
  flex:var(--condiv-flex) 1;
}
.condiv hr 
{
  margin-bottom: 30px;
}
.condiv .description
{
  font-size: 0.75em;
  line-height: 3px;
}
.keyheader
{
  font-size: 1em;
  font-weight: bolder;
}
.condiv .margintop
{
  padding-top: 10px;
}
.logodiv
{
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
nav
{
  padding:60px 30px 0px 30px ;
  flex:1.5 1;
  flex:var(--sidebar-flex) 1;
  background-image: url(/static/media/faded_bg.e1a30104.png);
}
.condiv
{
  padding: 100px ;
  background-color: #f4f5f9;
  flex:6 1;
  flex:var(--condiv-flex) 1;
}

.home
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
}
.profilepic
{
  border-radius: 50%;
  width: 200px;
  margin: 30px;
}
.typingeffect
{
  font-size: 3rem;
  font-size: var(--typing-font);
  margin-bottom: 30px;
}
.cardimage img
{
  align-items: center;
  padding-right: 20px;
  width: 100px;
  height: 100px;
}
.cardimage img
{
  align-items: center;
}
nav ul{
  font-size:1.6rem;
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li
{
  margin:40px;
}
a
{
  text-decoration: none;
  color: white;
}
.subtopic
{
  margin:10px 10px 30px 10px !important;
}
p{
  font-size: 1.2rem;
  font-size: var(--fontsize-p);
}
h1,h2,h3,h4,p{
  margin: 10px;
}
.social
{
  position: absolute;
  margin-top: 20px;
  display: flex;
  bottom: 60px;
}
.social i
{
  padding-right: 30px;
  font-size: 25px;
  color: #9a9b9c;
}
.social i:hover
{
  color: #ffffff;
}
.widecard,.widepic
{
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
  background-color: #ffffff;
  padding-left: 10px;
  padding-top: 8px;
}
.widecard:hover,.widepic:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.widecard a
{
  color: darkblue;
}
.active
{
  font-weight: bolder;
}
.skills ul li
{
  list-style-type:none;
  margin:30px;
}
.alignleft {
	float: left;
}
.alignright {
	float: right;
}
.clear 
{
  clear: both;
}
.skill
{
  background-color: #ffffff;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-top: 3px;
  padding-right: 3px;
  border: 1px;
  border-color: lightgrey;
  border-style: solid;
}
.pointimage
{
  width: 20px;
  height: 20px;
  padding-left: 1px;
}
@media all and (max-width: 450px) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  .App
  {
    flex-direction: column;
  }
  .sidebar
  {
    padding: 0px;
    background-image: url(/static/media/faded_bg.e1a30104.png);
  }
  .condiv
  {
    padding: 50px ;
  }
  nav 
  {
    align-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    text-align: center;
    margin: 5px;
  }
  .social
  {
    left:0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    bottom: 30px;
  }
  .social i
  {
    padding-right: 0px;
  }
}
